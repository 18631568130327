.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance: textfield !important;
}

.limit-characters-100{
  @include limit-character(100px)
}

.limit-characters-144{
  @include limit-character(144px)
}

.break-2-line {
  -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: 100px;
    word-wrap: break-word;
    outline: none;
    text-decoration: none !important;
}

.text-capitalize{
  text-transform: capitalize;
}
