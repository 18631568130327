// _overrides.scss
// This file is for last resort css rules, mostly used to override vendor/plugins css rules
// This is the only file in which using "!important" is not discouraged

body {
  font-family: Rubik, normal !important;
}

.btn-tp-default {
  color: $blue-light !important;
  border: 1px solid #ffffff !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  &:hover {
    background: $blue-35;
    border: 1px solid #ffffff;
    border-radius: 4px;
  }
  &:active {
    color: $blue-100 !important;
    background: $blue-light;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 4px;
  }
  &:focus {
    color: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0 0 0 2px #040622, 0 0 0 4px $green-bright !important;
    box-sizing: border-box;
    border-radius: 8px;
  }
  &:disabled {
    background: $gray-75;
    border: 1px solid $gray-1;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
.btn-modal-default {
  color: $gray-75;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  &:hover {
    color: $gray-75;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.btn-modal-confirm {
  color: white;
  background-color: $blue-100;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  &:hover {
    color: white;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.table-tp {
  @include media-breakpoint-down(sm) {
    margin-left: 24px;
  }
  th,
  td {
    border-bottom: none !important;
    border-top: none !important;
    color: $gray-1;
    vertical-align: middle;
  }
  th {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    @include media-breakpoint-down(sm) {
      flex-shrink: 0;
      font-size: 14px;
    }
  }

  td {
    font-size: 14px;
    line-height: 20px;
    @include media-breakpoint-down(sm) {
      font-size: 16px;
    }
  }

  tr {
    border-radius: 4px;
  }

  tbody {
    tr {
      background: $blue-35;
      border-bottom: 4px solid #040827;
      &:hover {
        background: $blue-100;
      }
      @include media-breakpoint-down(sm) {
        border-bottom: 8px solid #040827;
      }
    }
  }
}

.tp-badge-approved {
  color: $green-brighter;
  font-size: 11px;
  line-height: 16px;
  font-weight: normal;
  text-transform: uppercase;
  background: $blue-35;
  border-radius: 10px;
  padding: 0px 6px;
}

.tp-badge-rejected {
  color: #ff003d;
  font-size: 11px;
  line-height: 16px;
  font-weight: normal;
  text-transform: uppercase;
  background: none;
}

.tp-badge-onoff-active {
  font-size: 11px;
  line-height: 16px;
  font-weight: normal;
  border-radius: 12px;
  padding: 0 5px;
  max-height: 16px;
}
.tp-badge-onoff-active--yes {
  color: $blue-100;
  background-color: #00c1d4;
}
.tp-badge-onoff-active--no {
  color: black;
  background-color: $blue-medium;
}

.pagination {
  align-items: center;
  margin: 0;
  .break-me {
    display: none;
  }
  li {
    margin-right: 20px;
    a {
      font-size: 14px;
      line-height: 20px;
      color: $white-30 !important;
      background-color: transparent;
      border: none;
      padding: 4px 7px;

      transition: 0.25s ease;
      &:hover {
        color: white !important;
        background-color: $blue-100;
        border-radius: 4px;
      }
      &:focus {
        outline: none;
        border: 1px solid #41f075;
        border-radius: 4px;
        box-shadow: none;
      }
      @include media-breakpoint-down(sm) {
        font-size: 16px;
      }
    }
  }

  .previous {
    display: flex;
    border-radius: 4px;
    a {
      display: flex;
      width: 24px;
      height: 24px;
      background-image: url(/static/images/arrow-left.svg);
      &:hover {
        background-color: $blue-100;
        background-image: url(/static/images/arrow-left.svg);
      }
    }
    a[aria-disabled="true"] {
      pointer-events: none;
      background-color: transparent;
      background-image: url(/static/images/arrow-left-disabled.svg);
    }
  }

  .next {
    display: flex;
    border-radius: 4px;
    a {
      display: flex;
      width: 24px;
      height: 24px;
      background-image: url(/static/images/arrow-right.svg);
      &:hover {
        background-color: $blue-100;
        background-image: url(/static/images/arrow-right.svg);
      }
      &[aria-disabled="true"] {
        pointer-events: none;
        background-color: transparent;
        background-image: url(/static/images/arrow-right-disabled.svg);
      }
    }
  }
  li.active {
    a {
      color: $blue-light !important;
      border-bottom: 1px solid $green-brighter;
    }
    background-color: transparent;
    &:hover {
      background-color: $blue-100;
      border-radius: 4px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }
}

.tp-select {
  position: relative;
  color: $gray-3;
  font-size: 14px;
  line-height: 20px;
  width: 160px;
  background: url(/static/images/arrow-select.svg) no-repeat 140px $blue-100 !important;
  -webkit-appearance: none;
  background-position-x: 244px;
  border-radius: 5px;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none;
    color: $gray-3;
  }
  option {
    background: #031b4e;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 4px 4px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    &:hover {
      background: #031b4e;
    }
  }
}
.tp-select option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
}
.progress {
  height: 6px;
  border-radius: 0;
  .progress-bar {
    background-color: $green-brighter;
  }
}
.no-progress {
  height: 6px;
  background: $blue-100;
  border-top: 2px solid $white-30;
  border-bottom: 2px solid $white-30;
}
.no-progress-label {
  color: $white-30;
}
.modal-backdrop {
  background-color: rgba(5, 5, 5, 0.2);
  &.show {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    opacity: 1;
  }
}


//checkboxs

$checked-color: $blue-medium;
$unchecked-color: #DADADA;;
$checkbox-height: 16px;
$background-color:#667788;
$font-color:#efefef;
$duration: .4s;

/* Checkmark style starts */

@-moz-keyframes dothabottomcheck{
  0% { height: 0; }
  100% { height: $checkbox-height/2; }
}

@-webkit-keyframes dothabottomcheck{
  0% { height: 0; }
  100% { height: $checkbox-height/2; }
}

@keyframes dothabottomcheck{
  0% { height: 0; }
  100% { height: $checkbox-height/2;  }
}

@keyframes dothatopcheck{
  0% { height: 0; }
  50% { height: 0; }
  100% { height: $checkbox-height * 1.2; }
}
@-webkit-keyframes dothatopcheck{
  0% { height: 0; }
  50% { height: 0; }
  100% { height: $checkbox-height * 1.2; }
}
@-moz-keyframes dothatopcheck{
  0% { height: 0; }
  50% { height: 0; }
  100% { height: $checkbox-height * 1.2; }
}

input[type=checkbox]{
  display:none;
}

.check-box {
  height: $checkbox-height;
  width: $checkbox-height;
  background-color: transparent;
  border: $checkbox-height * .1 solid $unchecked-color;
  border-radius: 2px;
  position: relative;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: border-color ease $duration/2;
  -o-transition: border-color ease $duration/2;
  -webkit-transition: border-color ease $duration/2;
  transition: border-color ease $duration/2;
  cursor:pointer;

  &::before, &::after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: absolute;
      height: 0;
      width: $checkbox-height * .2;
      background-color: $checked-color;
      display: inline-block;
      -moz-transform-origin: left top;
      -ms-transform-origin: left top;
      -o-transform-origin: left top;
      -webkit-transform-origin: left top;
      transform-origin: left top;
      border-radius: 5px;
      content: ' ';
      -webkit-transition: opacity ease .5;
      -moz-transition: opacity ease .5;
      transition: opacity ease .5;
  }

  &::before {
      top:$checkbox-height * .72;
      left: $checkbox-height * .41;
      -moz-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
  }

  &::after {
      top: $checkbox-height * .37;
      left: $checkbox-height * .05;
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
  }
  &:hover{
    border: $checkbox-height * .1 solid $green-bright;
  }
}

input[type=checkbox]:checked + .check-box,
.check-box.checked{
  border-color:$checked-color;

  &::after{
      height: $checkbox-height * .5;
      -moz-animation: dothabottomcheck $duration/2 ease 0s forwards;
      -o-animation: dothabottomcheck $duration/2 ease 0s forwards;
      -webkit-animation: dothabottomcheck $duration/2 ease 0s forwards;
      animation: dothabottomcheck $duration/2 ease 0s forwards;
  }

  &::before{
      height: $checkbox-height * 1.2;
      -moz-animation: dothatopcheck $duration ease 0s forwards;
      -o-animation: dothatopcheck $duration ease 0s forwards;
      -webkit-animation: dothatopcheck $duration ease 0s forwards;
      animation: dothatopcheck $duration ease 0s forwards;
  }
}
::-webkit-scrollbar-track-piece {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: transparent;
  border-radius: 12px;
}
::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb:vertical {
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,.5);
  border-radius: 12px;
  height: 12px;
}
//Scrollbar for firefox
:root{
  scrollbar-color: rgba(82, 88, 101, 0.6) transparent !important;
  scrollbar-width: thin !important;
}
