.add-offer-modal{
  position: absolute;
  top: -411px;
  width: 520px;
  height: fit-content;
  max-height: 100vh;
  left: -423px;
  overflow: visible;
  @include media-breakpoint-down(sm){
    display: none;
    position: fixed;
    align-self: center;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
  }
  &.show{
      @include media-breakpoint-down(sm){
        display: flex;
    }
  }
  .modal-content{
    padding: 32px 32px 10px;
    background-color: $blue-lighter;
    border: 2px solid $blue-light;
  }

  .modal-header{
    border-bottom: none;
    padding-top: 0;
  }
  .modal-title{
    /* H3 - 24 px */

    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;

    /* Blue / Blue 100 */

    color: #031B4E;
  }

  .spinner-border{
    color:  rgba(5, 5, 5, 0.75);
    position: absolute;
    right: 10px;
    align-self: center;
    z-index: 10; // Adding z-index to allow this spinner to show on top of a modal input
  }

  .modal-error{
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    border-left: 4px solid #FF003D;


    .error-title{
      /* label - 12 */

      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;

      color: #FF003D;

    }

    .error-text{
      /* body-tiny */

      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;


      /* Text / Black-75 */

      color: rgba(5, 5, 5, 0.75);
    }
  }

  .form-control{
    background-color: $blue-lighter;
    border: 1px solid $blue-light;
    border-radius: 6px;
    &:hover{
      background-color: white;
    }
    &:focus{
      border: 1px solid $blue-medium;
      box-shadow: none;
    }
    &:disabled{
      background: $blue-light;
    }
  }

  .add__offer-label{
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1.125px;
    text-transform: uppercase;

    /* Text / gray */

    color: #525865;

    &--has-error{
      color: #ff003d;
    }
  }

  .add__offer-input{
    font-family: Rubik;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: center;

    /* Text / gray-75 */

    color: rgba(82, 88, 101, 0.75);

      &:not(:placeholder-shown){
        font-style: normal;
        color: $black-75;
      }
    &--has-error{
      border: 1px solid #ff003d;
    }
  }


  .modal-footer{
    border-top: none;
    justify-content: flex-start;

    button{
      /* Blue / Blue 100 */
      background: $blue-100;
      border: none;
      border-radius: 4px;
      opacity: 1;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      /* identical to box height, or 137% */

      text-align: center;
      color: #FFFFFF;

      &:hover{
        background-color: $blue-medium;
      }
      &:active{
        background: $dark-blue;
      }
      &:focus{
        box-shadow: 0 0 0 2px $blue-lighter, 0 0 0 4px $blue-100;
      }
      &:disabled{
        background: $gray-2;
        color: $gray-1
      }
    }
  }

}
