.home__info-project {
  background: $blue-35;
  padding-top: 75px;
  padding-bottom: 80px;
  border-radius: 0px 0px 0px 40px;
  @include media-breakpoint-down(sm){
    margin-left: 0px;
    padding: 44px 0 32px 0;
  }
}
.home__info-project__title {
  color: white;
  font-size: 36px;
  line-height: 43px;
  padding-left: 69px;
  font-weight: normal;
  max-width: 380px;
  display: -webkit-box;
  padding-top: 12px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @include media-breakpoint-down(sm){
    padding-left: 24px;
  }
}
.home__info-project__sub-title {
  color: $gray-2;
  font-size: 18px;
  line-height: 21px;
  font-weight: normal;
  padding-left: 69px;

  @include media-breakpoint-down(sm){
    padding-left: 24px;
  }
}
.home__info-project__card-group {
  display: inline-flex;
  padding-right: 165px;

  @include media-breakpoint-down(sm){
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-padding: 48px;
    -webkit-overflow-scrolling: touch;
    padding-left: 24px;
    padding-right: 30px;
    margin-top: 12px;
  }
}
.home__info-project__card {
  display: flex;
  box-sizing: border-box;
  border-radius: 13px;
  width: 160px;
  height: 120px;
  text-align: center;
  color: white;
  margin-left: 30px;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(95, 116, 166, 0.05);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);

  @include media-breakpoint-down(sm){
    margin-left: 0px;
    scroll-snap-align: start;
    flex-shrink: 0;
    margin-right: 20px;
  }
}
.home__info-project__card-text {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 3px;
  padding-top: 22px;
  margin-bottom: 0;
}
.home__info-project__card-text--larger {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 0;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 0;
}
.home__info-project__card-value {
  font-size: 48px;
  line-height: 22px;
  padding-bottom: 18px;
}

