.login-content {
  display: flex;
  color: white;
  min-height: 100vh;
  background: $dark-blue;
}
.container {
  align-self: center;
}

.login-content__title {
  color: $green-bright;
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 28px;
}
.login-content__subtitle {
  font-size: 15px;
  line-height: 22px;
  color: $gray-1;
}
.login__form {
  margin-top: 27px;
}
.login__form-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: $gray-2;
  text-transform: uppercase;
}
.login__form-input {
  color: #ffffff;
  border-radius: 6px;
  background: $blue-35;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  border: 1px solid $blue-light;
  &:hover {
    background: $dark-blue;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: none;
  }
  &:focus {
    color: #ffffff;
    background: $blue-35;
    border: 1px solid $green-brighter;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: none;
  }
}
.login__form-error-msg {
  color: #ff003d;
  align-self: center;
  img {
    margin-right: 8px;
  }
}

.login__form-submit-row {
  display: flex;
  align-self: center;
  margin-top: 16px;
  justify-content: space-between;
}

.login__form-button {
  border: none;
  padding: 9px 24px;
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  border-radius: 4px;
  background: $blue-35;
  border: 2px solid transparent;

  &:hover {
    background: $blue-100;
    border: 2px solid transparent;
  }
  &:active {
    background: $green-bright;
    color: $blue-100;
  }
  &:focus {
    background: $blue-35;
    border: 2px solid $blue-lighter;
    box-shadow: none !important;
  }
}

.login__form-group--has-error {
  color: #ff003d;
}

.login__form-group--has-error::after {
  content: "Please enter a value";
  color: #ff003d;
  align-self: center;
  margin-top: 3px;
  font-size: 12px;
  line-height: 16px;
}

.login__form-label--has-error{
  color: #ff003d;
}
.login__form-input--has-error{
  border: 1px solid #ff003d;
}
