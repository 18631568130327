.header {
  background: $blue-35;
  @include media-breakpoint-down(sm){
    margin-left: -24px;
  }
}

.header__items {
  margin-left: 75px;
  padding-top: 30px;
  @include media-breakpoint-down(sm){
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 24px 0 48px;
  }
}

.header__items__logo {
  background-image: url(/static/images/logo-header.svg);
  width: 133px;
  height: 32px;
  display: inline-flex;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: center;
}

.header__items-right {
  float: right;
  padding-right: 165px;
  @include media-breakpoint-down(sm){
    padding-right: 0;
  }
}

.header__items__link {
  color: #b0bac9;
  font-size: 14px;
  line-height: 20px;
  border: none;
  background: none;
  margin-left: 32px;
  padding-top: 6px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  &:hover {
    text-decoration: none;
    color: #b0bac9;
  }
  &:focus {
    box-shadow: none;
  }

  @include media-breakpoint-down(sm){
    display: flex;
    padding-left: 0;
    font-size: 16px;
  }
}

.header__items__username {
  color: $gray-2;
  text-transform: uppercase;
  font-size: 14px;

  @include media-breakpoint-down(sm){
    display: none;
  }
}
.header__items__icon {
  padding-left: 22px;
}
.header__items__link--active {
  border-top: 1px solid $green-brighter;
  border-radius: 0;
}
.icon-logout {
  background-image: url(/static/images/logout.svg);
}
.btn-logout {
  outline: none;
  padding: 0;
  margin-left: 20px;
  &:focus {
    box-shadow: none;
  }
}
