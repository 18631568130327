.selected-filter {
  display: inline-flex;

  border: 1px solid rgba(65, 240, 117, 0.3);
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 12px;
  margin-top: 12px;
  padding: 2px 8px;
  align-items: center;

  background: linear-gradient(0deg, rgba(3, 27, 78, 0.35), rgba(3, 27, 78, 0.35));

  &:hover {
    border-color: white;
    color: white;
  }
}
.selected-filter__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  color: $green-brighter;

  .selected-filter:hover > & {
    color: white;
  }
 }
.selected-filter__btn {
  background-image: url(/static/images/remove-selected-filter.svg);
  background-color: transparent;
  border: none;
  border-radius: 20px;

  width: 16px;
  height: 16px;
  margin-left: 13px;

  &:focus {
    outline: none;
    border: 1px solid white;
  }

  &:hover { 
    background-image: url(/static/images/remove-selected-filter-white.svg);
    background-color: rgba(255, 255, 255, 0.2);
  }

  .selected-filter:hover > & {
    background-image: url(/static/images/remove-selected-filter-white.svg);
  }
}
