.offer-filter-input__label {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  color: $gray;

  margin-top: 20px;
}
.offer-filter-input__button {
  display: flex;

  padding: 9px 16px;
  width: 262px;
  height: 40px;
  background: $blue-lighter;
  outline: none !important;
  justify-content: space-between;

  border: 1px solid #e0e7ff;
  box-sizing: border-box;
  border-radius: 5px;

  color: $gray-75;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: left;

  &:focus {
    border-color: $blue-100;
  }

  &-open{
    border-radius: 5px 5px 0 0;
  }

  .arrow-icon-up {
    background-image: url(/static/images/arrow-up.svg);
  }
  .arrow-icon-down {
    background-image: url(/static/images/arrow-down.svg);
  }
}
.offer-filter-input__checked-items {
  height: 40px;
  white-space: nowrap;
  width: 195px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.offer-filter-input__list {
  position: absolute;
  background: $blue-lighter;
  padding: 0;
  padding-top: 4px;
  overflow: auto;

  max-height: 164px;
  width: 262px;

  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(95, 116, 166, 0.4), inset 0px 4px 4px rgba(95, 116, 166, 0.3);
  border-radius: 0px 0px 5px 5px;
  border: 1px solid $blue-100;

  .offer-filter-input__button:focus ~ & {
    border-top: none;
  }
}

.offer-filter-input__list-item {
  list-style: none;
  margin: 6px;
  span {
    padding-left: 22px;
    font-size: 14px;
    display: block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .form-check {
    height: 34px;
    color: $gray-1;
    display: flex;
    padding-left: 6px;
    align-items: center;
    padding: 6px 2px 6px 6px;
    border-radius: 4px;
    line-height: 1;
  }
}

.form-check--checked {
  background: $blue-light;
}
