.filter-offer__content {
  position: absolute;
  width: 312px;
  background-color: $blue-lighter;
  border: 2px solid #e0e7ff;
  border-radius: 4px;
  padding: 28px 24px;
  margin-left: 17px;
  z-index: 1;

  -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.3s; /* Firefox < 16 */
   -ms-animation: fadein 0.3s; /* Internet Explorer */
    -o-animation: fadein 0.3s; /* Opera < 11.1 */
       animation: fadein 0.3s;
}

.group-action-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  button {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    background: none;
    outline: none;
    box-shadow: none;

    padding: 0;
    &:focus {
      box-shadow: none;
    }
  }
  .btn-clear-all {
    color: $gray-75;
  }
  .btn-apply {
    color: $blue-100;
  }
}
.filter-offer__title {
  display: flex;

  color: rgba(82, 88, 101, 0.75);
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.filter-offer__content--position-top{
  bottom: 38px;
}
