.offer-detail {
  background: $blue-35;
  padding: 78px 73px 58px;
  border-radius: 0px 0px 0px 40px;
  @include media-breakpoint-down(sm){
    padding: 48px 24px;
    max-width: 100%;
  }

  .product__status {
    justify-content: center;
    margin-right: 15px;
  }

  &__group-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.offer-detail__info {
  display: flex;
  justify-content: space-between;
  padding-bottom: 34px;
}
.offer-detail__info__code {
  display: flex;
  justify-content: space-between;
  align-content: center;
  min-width: 300px;
  max-width: 64%;
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  border-radius: 4px;
  margin-right: 30px;
  @include media-breakpoint-down(sm){
    margin-right: 0;
    min-width: 0;
  }

  &:hover {
    background-color: $blue-100;
    & .offer-detail__info__button {
      display: flex;
    }
  }
}

.offer-detail__info__button {
  display: none;
  align-self: center;
  height: 32px;
}

.offer-detail__info__item {
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  font-size: 12px;
  line-height: 16px;
  color: #525865;

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #b0bac9;
    margin-bottom: 8px;
  }

  &--approval{
    padding: 0;
  }
}
.offer-detail__list {
  padding: 59px 73px 0px;
  display: flex;
  padding-right: 0;
  min-width: 540px;
  flex-direction: column;
  @include media-breakpoint-down(sm){
    padding: 40px 24px;
    min-width: 0;
    max-width: 100%;
  }
}

.offer-detail__list__item {
  display: flex;
  .detail-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $blue-medium;
    padding-bottom: 28px;
    min-width: 158px;
  }
  .detail-info {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $blue-light;
    margin-left: 32px;

    // break line
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 38px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: 349px;
    word-wrap: break-word;
    outline: none;
    text-decoration: none !important;
  }

  .detail-url{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $gray-1;
    outline: none;
    text-decoration: none !important;
    &:hover {
      color: white;
    }
    &:focus {
      color: white;
      border-bottom: 1px solid #41f075;
    }
  }

}
.offer-detail__list__item:last-of-type {
  margin-bottom: 55px;
}
.confirmation-message {
  color: #5f74a6;
  height: 112px;
  display: flex;
  flex-flow: wrap;
}
.confirmation-message__title {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}
.confirmation-message__subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  margin-top: 24px;
}
.modal-offer-detail {
  .modal-header {
    border-bottom: none;
    padding: 32px 32px 0;
  }
  .modal-body {
    padding: 24px 32px 0;
    .form-group {
      margin-bottom: 0px;
    }
  }
  .modal-footer {
    padding: 24px 32px 35px;
    border-top: none;
    justify-content: left;
  }

  .modal-title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: $blue-100;
  }
}
.offer-detail__list__item:last-child {
  margin-bottom: 56px;
}

.offer-detail-util-col {
  background: $blue-35;
  height: 358px;
}
.offer-detail__assets {
  background: $blue-35;
  @include media-breakpoint-down(sm){
    background: none;
  }
}

.offer-detail__list-assets {
  background: $dark-blue;
  position: absolute;
  overflow: auto;
  border-radius: 28px;
  width: 444px;
  max-height: 768px;

  margin-top: 76px;
  padding: 32px 22px;
  @include media-breakpoint-down(sm){
    position: relative;
    width: 100%;
    margin: 0;
  }
}
.offer-detail__asset__group-info {
  display: flex;
  flex-direction: column;
}
.offer-detail__asset {
  background: $blue-35;
  font-size: 14px;
  padding: 20px 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  border-radius: 6px;
  @include media-breakpoint-down(sm){
    &:first-child{
      margin-top: 12px;
    }
  }
}

.offer-detail__asset-filename {
  color: $gray-2;
  -webkit-line-clamp: 2;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  align-items: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 34px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: 200px;
  word-wrap: break-word;
  @include media-breakpoint-down(sm){
    width: 100px;
  }
}
.offer-detail__asset-menu {
  background-image: url(/static/images/more-details.svg);
  background-position: center;
  background-repeat: no-repeat;
  outline: none;
  height: 34px;
  box-shadow: none !important;
  &:hover {
    background-image: url(/static/images/more-details-hover.svg);
  }
  &:focus {
    background-image: url(/static/images/more-details-focus.svg);
  }
  &:disabled {
    background-image: url(/static/images/more-details-disabled.svg);
  }
}
.offer-detail__asset-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $gray-1;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  max-width: 200px;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-top: 4px;
}
.offer-detail__asset-subject {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: white;
}
.offer-detail__asset__dropdown {
  width: 140px;
  border: 2px solid $blue-light;
  left: -28px !important;
  .dropdown-item {
    color: $gray-75;
    padding: 6px;
    margin: 6px 8px;
    border-radius: 4px;
    background: transparent;
    width: -webkit-fill-available;
    border: 1px solid transparent;

    &:hover {
      background: $gray-3;
    }
    &:focus {
      border: 1px solid $blue-medium;
      outline: none;
    }
  }
}
.offer-detail__export-xls {
  display: flex;
  float: right;
}

.align-actions {
  display: flex;
  justify-content: center;
  max-width: 50%;
  margin-top: -4px;
}
.left-spacing-32 {
  margin-left: 32px;
}
.limit-characters-256 {
  @include limit-character(256px);
}

.divider {
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid $white-30;
  width: 100%;
}

.label-progress-bar {
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  justify-content: space-between;

  color: #ffffff;

  margin-top: 35px;
  margin-bottom: 12px;
}
.offer-detail__notes-list {
  margin-bottom: 60px;
  min-width: 540px;
  max-height: 700px;
  overflow-x: auto;
  @include media-breakpoint-down(sm){
    min-width: 0;
  }
}

.offer-detail__note {
  padding: 0px 73px;
  display: flex;
  margin-top: 41px;
  flex-direction: column;
  @include media-breakpoint-down(sm){
    padding: 0px 24px;
    &:first-child{
      margin-top: 0;
    }
  }
}
.offer-detail__note-user {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  align-self: center;
  text-transform: uppercase;
}
.offer-detail__note-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 12px;
  color: $gray-1;

  max-width: 540px;
}
.offer-detail__note-tag,
.offer-detail__asset-tag {
  font-size: 11px;
  line-height: 16px;
  margin-left: 8px;
  padding: 0 8px;
  border-radius: 12px;
  color: $blue-100;
}
.offer-detail__note-created {
  font-size: 12px;
  line-height: 16px;
  text-transform: lowercase;

  margin-left: 8px;
  border-radius: 12px;
  color: $blue-medium;
}

.legality-analysis-modal {
  position: absolute;
  width: 537px;
  height: auto;
  top: 70px;
  left: 70px;

  margin-top: 17px;
  padding-right: 0 !important;
}
//Tags collors
.tag-LA,
.tag-FA {
  background: #ff8d69;
}
.tag-PU,
.tag-PA,
.tag-NE,
.tag-DE {
  background: $gray-2;
}
.tag-SE {
  background: #00c1d4;
}
.tag-NO {
  background: #ff699f;
}

.preview-modal {
  .modal-content {
    background-color: $blue-lighter;
  }
  .modal-header {
    color: $blue-100;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    border-bottom: none;
  }
  .modal-body p {
    color: $blue-100;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    word-wrap: break-word;
  }
  .modal-body img {
    width: 100%;
  }

  .no-preview-available{
    text-align: center;
  }
}
