.base-template {
  background: $dark-blue;
  padding-left: 90px;
  min-height: 100vh;
  overflow: hidden;
  color: white;

  @include media-breakpoint-down(sm){
    padding-left: 0px;
  }
}
