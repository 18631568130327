// colors
$white-30: rgba(255, 255, 255, 0.3);
$dark-blue: linear-gradient(180deg, #030518 4.17%, #040829 65.62%, #030518 100%);
$green-bright: #39d36a;
$green-brighter: #41f075;
$gray: #525865;
$gray-1: #8798ad;
$gray-2: #b0bac9;
$gray-3: #F1F1F1;;
$gray-75: rgba(82, 88, 101, 0.75);
$blue-35: linear-gradient(0deg, rgba(3, 27, 78, 0.35), rgba(3, 27, 78, 0.35));
$blue-100: #031b4e;
$blue-light: #e0e7ff;
$blue-lighter: #f5f8ff;
$blue-medium: #5f74a6;
$gradient-turquoise: linear-gradient(180deg, rgba(105, 210, 255, 0.35) 0%, rgba(255, 255, 255, 0) 100%);
$black-75: rgba(5,5,5,0.75);

