.search-offer {
  font-size: 14px;
  line-height: 20px;
  width: auto;
  flex-flow: wrap-reverse;
}

.search-offer--border {
  border-bottom: 1px solid green;
}

.search-offer__icon {
  background-image: url(/static/images/search-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;

  &:hover {
    background-color: $blue-100;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    background-image: url(/static/images/search-icon-focus.svg);
  }
}
.search-offer__icon--close {
  background-image: url(/static/images/close-search.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 10px;
  &:focus {
    background-image: url(/static/images/close-search.svg);
  }
}

.search-offer__input {
  background-color: transparent;
  border: none;
  padding: 0;

  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  &:focus {
    outline: none;
    background-color: transparent;
    box-shadow: none;
    color: white;
  }
}
