.offers-list {
  padding-top: 47px;
  padding-left: 65px;
  padding-right: 165px;
  position: relative;

  @include media-breakpoint-down(sm) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.offers-list__title {
  color: $blue-medium;
  font-size: 24px;
  line-height: 28px;

  @include media-breakpoint-down(sm) {
    padding-left: 24px;
  }
}
.offers-list-utilities {
  display: flex;
  padding-top: 44px;
  padding-bottom: 39px;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    padding: 24px;
  }
  &__add {
    position: relative;
    float: right;
    display: flex;
    margin-top: 10px;
    .btn-export {
      margin-right: 12px;
    }
  }
}
.no-offer {
  display: inline-flex;
  color: $gray-1;
  font-size: 16px;
  line-height: 22px;

  padding-top: 22px;
  padding-bottom: 20px;
  padding-left: 24px;
  width: 100%;

  background: $blue-35;
  border-radius: 4px;
}

.sticky-table {
  @include media-breakpoint-down(sm) {
    display: flex;
    position: -webkit-sticky;
    padding: 0.75rem !important;
    position: sticky;
    width: 100%;
    min-width: 100px;
    max-width: 100%;
    left: 0;
    background: #040721; //shade to emulate $dark-blue gradient
  }
}

.product {
  border-right: 1px solid rgba(238, 242, 255, 0.2);
  padding: 0 !important;

  @include media-breakpoint-down(sm) {
    display: flex;
    position: -webkit-sticky;
    padding: 0.75rem !important;
    position: sticky;
    width: 100%;
    min-width: 100px;
    max-width: 110%;
    left: 0px;
    right: -5px;
    background-color: #040e33; //shade to emulate $blue-35 + $dark-blue gradient
  }

  &:hover {
    background: linear-gradient(270deg, rgba(241, 241, 241, 0.2) 7.69%, rgba(3, 5, 24, 0.2) 66.15%);
  }

  &__group-actions {
    display: flex;
    justify-content: space-between;
    @media only screen and (min-width: 1700px) {
      max-width: 80%;
    }
  }

  &__link {
    display: flex;
    flex-grow: 1;
    color: white !important;
    padding: 11px;
    flex-direction: column;
    border: 1px solid transparent;
    text-decoration: none !important;

    &:hover {
      color: white;
      text-decoration: none;
    }
    &:focus {
      border: 1px solid #41f075;
      box-sizing: border-box;
      border-radius: 4px 0px 0px 4px;
      outline: none;
    }
  }

  &__id {
    margin-bottom: 0;
  }

  &__name {
    color: $green-brighter;
    margin-bottom: 0;
  }

  &__url {
    display: block;
    max-width: 144px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $gray-1;
    outline: none;
    text-decoration: none !important;
    &:hover {
      color: white;
    }
    &:focus {
      color: white;
      border-bottom: 1px solid #41f075;
    }
  }

  &__status {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    color: white;
    background: $blue-100;
    border-radius: 4px;
  }

  &__btn-icon {
    padding: 0;
    border-radius: 20px;

    &:hover {
      background: linear-gradient(180deg, #030518 4.17%, #040829 65.62%, #030518 100%);
      border-radius: 100px;
    }
    &:focus {
      border: 1px solid #e0e7ff;
      box-sizing: border-box;
      border-radius: 20px;
      box-shadow: none !important;
    }
  }

  &__btn-icon[disabled] {
    &:hover {
      background: none;
      border-radius: 100px;
    }
  }
}

.offer-is-rejected {
  td,
  .product__id,
  .product__name,
  .product__status,
  .product__url {
    color: $blue-medium !important;
  }
}
.btn-recuse {
  margin-left: 20px;
}
.icon-approve {
  background-image: url(/static/images/check.svg);
  &:hover {
    background-image: url(/static/images/check-hover.svg);
  }
}
.icon-recuse {
  background-image: url(/static/images/recuse.svg);
  &:hover {
    background-image: url(/static/images/recuse-hover.svg);
  }
}
.icon-delete {
  background-image: url(/static/images/trash.svg);
  &:hover {
    background-image: url(/static/images/trash-hover.svg);
  }
}
.icon-delete--disabled {
  background-image: url(/static/images/trash-disabled.svg);
  &:hover {
    background-image: url(/static/images/trash-disabled.svg);
  }
}
.icon-filter {
  background-image: url(/static/images/filter-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 16px;
  margin-top: 10px;

  &:hover {
    background-color: $blue-100;
  }
  &:focus {
    outline: none;
    box-shadow: none;
    background-image: url(/static/images/filter-icon-focus.svg);
  }
}
.has-filter-icon {
  background-image: url(/static/images/has-filter-icon.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 16px;
  margin-top: 10px;
}
.icon-default {
  width: 20px;
  height: 20px;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: center;
  @include media-breakpoint-down(sm) {
    width: 24px;
    height: 24px;
  }
}
.approved-flag {
  display: flex;
  border-radius: 4px 0px 0px 4px;
  border-left: 2px solid $green-brighter;
}
.form-number-page {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }
}
.pagination-row {
  display: flex;
  margin-bottom: 78px;
  margin-top: 20px;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.pagination-row__text {
  color: $gray-1;
  font-size: 12px;
  margin-right: 12px;
  height: 100%;
  line-height: 16px;
  align-self: center;

  @include media-breakpoint-down(sm) {
    margin-bottom: 16px;
  }
}
.pagination-row__text:first-of-type {
  border-right: 1px solid $green-brighter;
  padding-right: 12px;
}
.pagination-row__input {
  color: $gray-1;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  width: 36px;
  height: 24px;
  padding: 4px;

  outline: none;
  background: none;
  border-radius: 4px;
  border: 1px solid #ffffff;
}
.pagination-row__button {
  width: 24px;
  height: 24px;
  margin-left: 6px;
  border: none;
  transition: 0.25s ease;
  background-image: url(/static/images/arrow-right.svg);
  &:hover {
    border: none;
    color: white;
    background: $blue-100;
    border-radius: 4px;
    background-position-y: center;
    background-image: url(/static/images/arrow-right.svg);
  }
  &:focus {
    border: 1px solid $green-brighter;
    border-radius: 4px;
    box-shadow: none;
  }
}
.offers-list-utilities__filters {
  display: flex;
  align-items: center;
}

.btn-sort-column {
  background-repeat: no-repeat !important;
  background-position: center !important;
  padding-left: 20px;
}
.btn-sort-arrow-default {
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    background: url(/static/images/sort-arrow-default.svg), transparent;
  }
}
.btn-sort-arrow-down {
  cursor: pointer;
  background: url(/static/images/sort-arrow-down.svg), transparent;
}
.btn-sort-arrow-up {
  cursor: pointer;
  background: url(/static/images/sort-arrow-up.svg), transparent;
}
.header-sort-list:hover > .btn-sort-arrow-default{
    background: url(/static/images/sort-arrow-default.svg), transparent;
}
.header-sort-list {
  cursor: pointer;
}
.loading {
  text-align: center;
  p {
    color: #ccc;
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    width: 0.6em;
    height: 0.6em;
    margin: 0.19em;
    background: #f5f8ff;
    border-radius: 0.6em;
    animation: loading 1s infinite alternate;
  }
  span:nth-of-type(2) {
    background: #e0e7ff;
    animation-delay: 0.2s;
  }
  span:nth-of-type(3) {
    background: #5f74a6;
    animation-delay: 0.4s;
  }
  span:nth-of-type(4) {
    background: #5f74a6;
    opacity: 20%;
    animation-delay: 0.6s;
  }
  span:nth-of-type(5) {
    background: #2dbc5a;
    animation-delay: 0.8s;
  }
  span:nth-of-type(6) {
    background: #2dbc5a;
    animation-delay: 1s;
  }
  span:nth-of-type(7) {
    background: #2dbc5a;
    animation-delay: 1.2s;
  }

  @keyframes loading {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
