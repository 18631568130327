// _base.scss
// This partial contains basic css rules for our app, like vendor scss files, styles for headers, inputs, etc.
// Any custom css rules that use ids and classes should not be defined here

// General scrollbar
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a991;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a9a9a991;
  opacity: 10px;
}
