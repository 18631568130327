.offer-modal {

  .modal-dialog {
    top: 30%;
  }

  .modal-content {
    padding: 32px 32px 35px;
  }

  .modal-header{
    border-bottom: none;
    padding: 0;
    margin-bottom: 24px;

    .modal-title {
      font-size: 24px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      &.reject {
        color: #FF003D;
      }
      &.approve {
        color: #031B4E;
      }
    }
    .close {
      font-weight: 300;
      color: #5F74A6;
    }
  }

  .modal-body {
    padding: 0;
    margin-bottom: 24px;

    .confirmation-message {
      color: #525865;
      font-family: Rubik;
      font-style: normal;
      font-weight: 700;
      height: auto;

      &__title {
        font-size: 18px;
        line-height: 24px;
      }

      &__subtitle {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .modal-footer {
    border-top: none;
    padding: 0;
    justify-content: flex-start;

    button {
      margin: 0;

      &.btn {
        padding: 9px 24px;
        span {
          color: #FFFFFF;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          line-height: 22px;
          font-weight: 500;
        }
      }

      &.btn-approve {
        background: #031B4E;

      }

      &.btn-reject {
        background: #FF003D;
      }
    }
  }
}
